$(document).ready(function(){
	if( $('[data-mastercom-enrollment-form]').length){
		hbspt.forms.create({
	    	region: "na1",
	    	portalId: "2611577",
	    	formId: "52042473-aada-4de6-92ad-ef6ce9f99b60",
			target: "[data-mastercom-enrollment-form]",
		});
	}

	if( $('[data-visa-enrollment-form]').length){
		hbspt.forms.create({
	    	region: "na1",
	    	portalId: "2611577",
	    	formId: "52042473-aada-4de6-92ad-ef6ce9f99b60",
			target: "[data-visa-enrollment-form]",
		});
	}
})
