$(document).ready(function(){

	let a = document.getElementById("js-animated-logo");
	let b = a.contentDocument;
	let c = b.getElementById("enPZOrgK41h1");
	let clicked = false;

	$(window).on("resize scroll", function(){
		if( $(window).scrollTop() > 1) {
			$("body").addClass('is-scrolled');
			if(!clicked) {
				setTimeout(function(){
					c.dispatchEvent(new Event('click'));
				}, 10)
			}
			clicked = true;
		} else {
			if( $(window).scrollTop() == 0) {
				setTimeout(function(){
					c.dispatchEvent(new Event('click'));
				}, 10)
				clicked = false;
				$("body").removeClass('is-scrolled');
			}
		}
	})

});
