/* Chargeback Cost Calculator
-------------------------------------------- */

// Format Number as Money
Number.prototype.formatMoney = function (c, d, t) {
	var n = this,
		c = isNaN((c = Math.abs(c))) ? 2 : c,
		d = d == undefined ? '.' : d,
		t = t == undefined ? ',' : t,
		s = n < 0 ? '-' : '',
		i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
		j = (j = i.length) > 3 ? j % 3 : 0;
	return (
		s +
		(j ? i.substr(0, j) + t : '') +
		i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
		(c
			? d +
			  Math.abs(n - i)
					.toFixed(c)
					.slice(2)
			: '')
	);
};

// Get a Random Number Between Two Numbers
function randomNumber(min, max) {
	return Math.random() * (max - min) + min;
}

String.prototype.toProperCase = function () {
	return this.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
};

(function cb_cost_calculator() {
	'use strict';

	var done = false;

	function calculate() {
		// get the value of the slider handle without the dollar sign
		function getValue(x) {
			var a = parseInt(
				$(x + ' .c-calc-slider__handle')
					.text()
					.replace(/\$/g, ''),
				10
			);
			return a;
		}

		/* define variables affecting chargeback costs */
		var cb_per_month = getValue('#cb_per_month'),
			cb_per_year = cb_per_month * 12,
			// avg transaction value
			cost_per_cb = getValue('#cost_per_cb'),
			annual_revenue_lost = cb_per_year * cost_per_cb,
			// cb fees = $15 (avg fee) * number of cbs per year
			avg_cb_fee = 27,
			annual_cb_fees = cb_per_year * avg_cb_fee,
			// admin fees = 40% of annual revenue lost
			admin_fees = annual_revenue_lost * 0.4,
			// cost of goods and shipping = 50% of annual revenue lost
			goods_shipping = annual_revenue_lost * 0.5,
			total_chargeback_cost =
				annual_revenue_lost + annual_cb_fees + admin_fees + goods_shipping;

		var viewport_width = window.innerWidth;
		if (viewport_width <= 650) {
			$('#cb_calc_modal_btn').val('see what you could save!');
		}

		$(function () {
			var x = 0;
			if (done === false) {
				if (viewport_width <= 650) {
					console.log('small');
					var p;
					if (p) {
						p.appendTo('.s-calc-output__inner');
						p = null;
					} else {
						p = $('.c-cost-calculator__submit-wrapper').detach();
						p.appendTo('.s-calc-output__inner');
						p.css('max-height', '400px');
						$('#revenue_lost').css('margin-top', '10px');
					}
				}
				$('.js-waterfall-fade').each(function (index) {
					$(this)
						.delay(400 * index)
						.slideDown(300, function () {
							x += 1;
							if (x >= $('.js-waterfall-fade').length) {
								$('#cb_calc_modal_btn').addClass('is-active');
								$('#cb_calc_cta_arrow')
									.delay(700)
									.fadeIn(500, function () {
										$(this).addClass('a-pulse');
									});
							}
						});
				});
				done = true;
			}
		});
		$('#revenue_lost span').text('$' + annual_revenue_lost.formatMoney());
		$('#chargeback_fees span').text('$' + annual_cb_fees.formatMoney());
		$('#admin_fees span').text('$' + admin_fees.formatMoney());
		$('#goods_shipping span').text('$' + goods_shipping.formatMoney());
		$('#total_chargeback_cost span').text('$' + total_chargeback_cost.formatMoney());
	}

	$('.c-calc-slider').slider({
		range: 'min',
		value: 100,
		min: 1,
		max: 1000,
		create: function () {
			var handle = $(this).children('.c-calc-slider__handle'),
				data_type = $(this).attr('data-type') === 'dollars' ? '$' : '';
			handle.text(data_type + $(this).slider('value'));
		},
		slide: function (event, ui) {
			var handle = $(this).children('.c-calc-slider__handle'),
				data_type = $(this).attr('data-type') === 'dollars' ? '$' : '';
			if (ui.value === $(this).slider('option', 'max')) {
				handle.text(data_type + ui.value + '+');
				$('.s-calc-output__inner').addClass('is-max');
			} else {
				handle.text(data_type + ui.value);
				$('.s-calc-output__inner').removeClass('is-max');
			}
			calculate();
		},
	});

	$('#cost_per_cb .c-calc-slider').slider('option', 'max', 500);

	// on CTA button click
	$('#cb_calc_modal_btn').on('click', function () {
		// add scoping class to hubspot form
		$('.hbspt-form').addClass('s-cost-calculator-form');

		// get the chargebacks per month from the slider handle
		var cb_per_month_val = parseInt($('#cb_per_month .ui-slider-handle').text(), 10),
			// get option values based on slider range
			hs_select_val =
				cb_per_month_val < 10
					? 'Less Than 10'
					: cb_per_month_val <= 25
					? '10 to 25'
					: cb_per_month_val <= 50
					? '25 to 50'
					: cb_per_month_val <= 100
					? '50 to 100'
					: cb_per_month_val <= 250
					? '100 to 250'
					: cb_per_month_val <= 500
					? '250 to 500'
					: '500+';

		// @NOTE: hubspot clears the form on pretty much every event, so we make the data persist with this workaround
		$.fn.notifyHubspot = function () {
			function doEvent(obj, name) {
				try {
					var event = new Event(name, {
						target: obj,
						bubbles: true,
					});
					return obj ? obj.dispatchEvent(event) : false;
				} catch (e) {
					var event = document.createEvent('Event');
					event.initEvent(name, true, true);
					obj.dispatchEvent(event);
				}
			}

			$(this).each(function () {
				doEvent(this, 'change');
				doEvent(this, 'input');
				doEvent(this, 'blur');
			});

			return $(this);
		};

		//  fill select field in hubspot form with the value matching the slider
		$('[name="average_number_of_chargebacks_per_month"]').val(hs_select_val).notifyHubspot();

		// hide the hubspot form select field, since it's been prefilled
		// $('.hs_average_number_of_chargebacks_per_month').css({
		// 	height: '10px',
		// 	overflow: 'hidden',
		// 	visibility: 'hidden',
		// 	'z-index': '-1',
		// });
	});
})();
