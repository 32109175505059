const modal = {
	open: function (element) {
		let modal = $(element);
		modal.addClass('--is-open');
	},
	close: function (element) {
		let modal = $(element);
		modal.removeClass('--is-open');
	},
	closeButton: function () {
		let closeButton = $('.c-modal__close-btn');
		closeButton.on('click', function () {
			$(this).closest('.c-modal').removeClass('--is-open');
		});
	},
	// closeOnClick: function () {
	// 	let modal = $('.c-modal');
	// 	$('body').on('click', '.c-modal', function (event) {
	// 		console.log(event.target);
	// 		if ($(event.target).hasClass('c-modal')) {
	// 			console.log('yes');
	// 			modal.each(function () {
	// 				$(this).hide();
	// 			});
	// 		}
	// 	});
	// },
	init: function () {
		this.closeButton();
	},
};

$(document).ready(function () {
	modal.init();
});
