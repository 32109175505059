$(document).ready(function(){

	if( $('[data-table-of-contents-category]').length) {
				let toc = $('[data-table-of-contents-category]'),
					sidebar = toc.parent(),
					tocTop = toc.offset().top,
					mobile = false;


				$(window).on("resize load", function(){
					if( window.innerWidth <= 1199.98) {
						mobile = true;
						toc.removeClass('is-stuck is-absolute')
						toc.detach()
						$('.p-category__featured-image').after( toc )
					} else {
						mobile = false;
						toc.detach()
						$('.p-category__sidebar').append( toc )
					}
				});

				$(window).on("scroll load", function(){

					if(!mobile) {
						let windowTop = $(window).scrollTop();

						if(tocTop < windowTop + 80 ) {
							toc.addClass('is-stuck').removeClass('is-absolute');

							let total = sidebar.outerHeight() + sidebar.offset().top - toc.outerHeight() - 80;
							if( total < windowTop ) {
								toc.addClass('is-absolute').removeClass('is-stuck');
							}
						} else {
							toc.removeClass('is-stuck').removeClass('is-absolute');
						}
					}


					let currentPos = $(window).scrollTop();

					$('[data-section-anchor]').each(function(i) {
						if ($(this).offset().top - (window.innerHeight / 4) <= currentPos) {
							$('.c-table-of-contents__link.is-active').removeClass('is-active');
							$('.c-table-of-contents__link').eq(i).addClass('is-active');
						}
					});


				})
	}


});
