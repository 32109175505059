$(document).ready(function(){

	function selectText() {
		var sel, range;
		var el = $('.js-share-svg-modal-code')[0];

		if (window.getSelection && document.createRange) {
			sel = window.getSelection();
			window.setTimeout(function () {
				range = document.createRange(); //range object
				range.selectNodeContents(el); //sets Range
				sel.removeAllRanges(); //remove all ranges from selection
				sel.addRange(range); //add Range to a Selection.
				copyText();
			}, 1);
		} else if (document.selection) {
			//older ie
			sel = document.selection.createRange();
			range = document.body.createTextRange(); //Creates TextRange object
			range.moveToElementText(el); //sets Range
			range.select(); //make selection.
			copyText();
		}
	}

	function copyText(){
		document.execCommand("copy");
		$('.c-share-svg-modal__copied').addClass('is-visible')
		setTimeout(function(){
			$('.c-share-svg-modal__copied').removeClass('is-visible')

		}, 2000)
	}

	$('.c-share-svg-modal__button').on('click', function(){
		selectText();
	})

	$('.js-share-svg-modal-code').on('click', function(){
		selectText()
	})

	$('.js-share-svg-modal-close').on('click', function(){
		$('.c-share-svg-modal').removeClass('is-open');
		$('body').removeClass('modal-opened');
	})

	$('body').on('click', function(e){
		if( $(e.target).hasClass('js-share-svg-modal') ) {
			$('.c-share-svg-modal').removeClass('is-open');
			$('body').removeClass('modal-opened');
		}
	});

	$('.js-share-svg-button').on('click', function(){

		// fill modal with data
		let source = $(this).data('source'),
			url = $(this).data('url');

		if(source) {
			console.log(source)
		}else{
			console.log('no source');
		}

		$('.js-share-svg-modal-code').text('<a style="width: 100%; height: auto; margin: 40px 0; display: block; z-index: 1; position: relative;" href="'+ url +'" rel="external noopener"><span style="display: block; position: absolute; top: 0; right: 0; left: 0; bottom: 0;"></span><object type="image/svg+xml" data="' + source + '"></object></a>')

		// open modal
		$('.c-share-svg-modal').addClass('is-open');
		$('body').addClass('modal-opened');
	})

})
