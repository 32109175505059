$(document).ready(function(){

	let menu = $('.c-main-menu'),
		menuTop = menu.offset().top,
		wpadminbar = $('#wpadminbar');

	if(menu.length){
		if(wpadminbar.length){
			menu.css("top", "32px");
		}
	}

	let hasDropdown = $('.has-dropdown > a')

	$(window).on("resize load", function(){

		if( window.innerWidth <= 991.98) {
			hasDropdown.on("click", function(e){
				e.preventDefault();
				$(this).parent().toggleClass('is-toggled');
			})
		}
	});

	$(document).on("click", ".c-main-menu__mobile-trigger", function(e){
		menu.toggleClass('is-toggled');
	})

	$(document).on("click", function(e){
		if (!$(event.target).closest('.c-main-menu').length) {
			menu.removeClass('is-toggled');
		}
	})


})
