$(document).ready(function(){
	const options = {
		rootMargin: '-40% 0px -40% 0px',
		threshold: 0 // half of item height
	}

	const ebook = document.querySelectorAll('.c-shortcode-ebook');

	const observer = new IntersectionObserver(entries => {
		entries.forEach((entry) => {

			if (entry.isIntersecting && !entry.target.classList.contains('is-closed')) {
				entry.target.classList.add('is-active');
			} else {
				entry.target.classList.remove('is-active');
			}
		})
	}, options)

	if ('IntersectionObserver' in window) {
		ebook.forEach(ebook => observer.observe(ebook))
	}

	const close = document.querySelectorAll('.c-shortcode-ebook [data-shortcode-ebook-close]');
	close.forEach(el => {
		el.addEventListener('click', function(e){
			e.stopPropagation();
			e.preventDefault();
			el.closest('.c-shortcode-ebook').classList.add('is-closed');
			el.closest('.c-shortcode-ebook').classList.remove('is-active');
		})
	});
})
