/*
    A simple jQuery modal (http://github.com/kylefox/jquery-modal)
    Version 0.9.1
*/
!(function (o) {
	'object' == typeof module && 'object' == typeof module.exports
		? o(require('jquery'), window, document)
		: o(jQuery, window, document);
})(function (o, t, i, e) {
	var s = [],
		l = function () {
			return s.length ? s[s.length - 1] : null;
		},
		n = function () {
			var o,
				t = !1;
			for (o = s.length - 1; o >= 0; o--)
				s[o].$blocker &&
					(s[o].$blocker.toggleClass('current', !t).toggleClass('behind', t), (t = !0));
		};
	(o.jsmodal = function (t, i) {
		var e, n;
		if (
			((this.$body = o('body')),
			(this.options = o.extend({}, o.jsmodal.defaults, i)),
			(this.options.doFade = !isNaN(parseInt(this.options.fadeDuration, 10))),
			(this.$blocker = null),
			this.options.closeExisting)
		)
			for (; o.jsmodal.isActive(); ) o.jsmodal.close();
		if ((s.push(this), t.is('a')))
			if (((n = t.attr('href')), (this.anchor = t), /^#/.test(n))) {
				if (((this.$elm = o(n)), 1 !== this.$elm.length)) return null;
				this.$body.append(this.$elm), this.open();
			} else
				(this.$elm = o('<div>')),
					this.$body.append(this.$elm),
					(e = function (o, t) {
						t.elm.remove();
					}),
					this.showSpinner(),
					t.trigger(o.jsmodal.AJAX_SEND),
					o
						.get(n)
						.done(function (i) {
							if (o.jsmodal.isActive()) {
								t.trigger(o.jsmodal.AJAX_SUCCESS);
								var s = l();
								s.$elm.empty().append(i).on(o.jsmodal.CLOSE, e),
									s.hideSpinner(),
									s.open(),
									t.trigger(o.jsmodal.AJAX_COMPLETE);
							}
						})
						.fail(function () {
							t.trigger(o.jsmodal.AJAX_FAIL);
							var i = l();
							i.hideSpinner(), s.pop(), t.trigger(o.jsmodal.AJAX_COMPLETE);
						});
		else (this.$elm = t), (this.anchor = t), this.$body.append(this.$elm), this.open();
	}),
		(o.jsmodal.prototype = {
			constructor: o.jsmodal,
			open: function () {
				var t = this;
				this.block(),
					this.anchor.blur(),
					this.options.doFade
						? setTimeout(function () {
								t.show();
						  }, this.options.fadeDuration * this.options.fadeDelay)
						: this.show(),
					o(i)
						.off('keydown.jsmodal')
						.on('keydown.jsmodal', function (o) {
							var t = l();
							27 === o.which && t.options.escapeClose && t.close();
						}),
					this.options.clickClose &&
						this.$blocker.click(function (t) {
							t.target === this && o.jsmodal.close();
						});
			},
			close: function () {
				s.pop(),
					this.unblock(),
					this.hide(),
					o.jsmodal.isActive() || o(i).off('keydown.jsmodal');
			},
			block: function () {
				this.$elm.trigger(o.jsmodal.BEFORE_BLOCK, [this._ctx()]),
					this.$body.css('overflow', 'hidden'),
					(this.$blocker = o(
						'<div class="' + this.options.blockerClass + ' blocker current"></div>'
					).appendTo(this.$body)),
					n(),
					this.options.doFade &&
						this.$blocker
							.css('opacity', 0)
							.animate({ opacity: 1 }, this.options.fadeDuration),
					this.$elm.trigger(o.jsmodal.BLOCK, [this._ctx()]);
			},
			unblock: function (t) {
				!t && this.options.doFade
					? this.$blocker.fadeOut(this.options.fadeDuration, this.unblock.bind(this, !0))
					: (this.$blocker.children().appendTo(this.$body),
					  this.$blocker.remove(),
					  (this.$blocker = null),
					  n(),
					  o.jsmodal.isActive() || this.$body.css('overflow', ''));
			},
			show: function () {
				this.$elm.trigger(o.jsmodal.BEFORE_OPEN, [this._ctx()]),
					this.options.showClose &&
						((this.closeButton = o(
							'<a href="#close-modal" rel="jsmodal:close" class="close-modal ' +
								this.options.closeClass +
								'">' +
								this.options.closeText +
								'</a>'
						)),
						this.$elm.append(this.closeButton)),
					this.$elm.addClass(this.options.jsmodalClass).appendTo(this.$blocker),
					this.options.doFade
						? this.$elm
								.css({ opacity: 0, display: 'inline-block' })
								.animate({ opacity: 1 }, this.options.fadeDuration)
						: this.$elm.css('display', 'inline-block'),
					this.$elm.trigger(o.jsmodal.OPEN, [this._ctx()]);
			},
			hide: function () {
				this.$elm.trigger(o.jsmodal.BEFORE_CLOSE, [this._ctx()]),
					this.closeButton && this.closeButton.remove();
				var t = this;
				this.options.doFade
					? this.$elm.fadeOut(this.options.fadeDuration, function () {
							t.$elm.trigger(o.jsmodal.AFTER_CLOSE, [t._ctx()]);
					  })
					: this.$elm.hide(0, function () {
							t.$elm.trigger(o.jsmodal.AFTER_CLOSE, [t._ctx()]);
					  }),
					this.$elm.trigger(o.jsmodal.CLOSE, [this._ctx()]);
			},
			showSpinner: function () {
				this.options.showSpinner &&
					((this.spinner =
						this.spinner ||
						o('<div class="' + this.options.jsmodalClass + '-spinner"></div>').append(
							this.options.spinnerHtml
						)),
					this.$body.append(this.spinner),
					this.spinner.show());
			},
			hideSpinner: function () {
				this.spinner && this.spinner.remove();
			},
			_ctx: function () {
				return {
					elm: this.$elm,
					$elm: this.$elm,
					$blocker: this.$blocker,
					options: this.options,
				};
			},
		}),
		(o.jsmodal.close = function (t) {
			if (o.jsmodal.isActive()) {
				t && t.preventDefault();
				var i = l();
				return i.close(), i.$elm;
			}
		}),
		(o.jsmodal.isActive = function () {
			return s.length > 0;
		}),
		(o.jsmodal.getCurrent = l),
		(o.jsmodal.defaults = {
			closeExisting: !0,
			escapeClose: !0,
			clickClose: !0,
			closeText: 'Close',
			closeClass: '',
			modalClass: 'modal',
			blockerClass: 'jquery-modal',
			spinnerHtml:
				'<div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div>',
			showSpinner: !0,
			showClose: !0,
			fadeDuration: null,
			fadeDelay: 1,
		}),
		(o.jsmodal.BEFORE_BLOCK = 'jsmodal:before-block'),
		(o.jsmodal.BLOCK = 'jsmodal:block'),
		(o.jsmodal.BEFORE_OPEN = 'jsmodal:before-open'),
		(o.jsmodal.OPEN = 'jsmodal:open'),
		(o.jsmodal.BEFORE_CLOSE = 'jsmodal:before-close'),
		(o.jsmodal.CLOSE = 'jsmodal:close'),
		(o.jsmodal.AFTER_CLOSE = 'jsmodal:after-close'),
		(o.jsmodal.AJAX_SEND = 'jsmodal:ajax:send'),
		(o.jsmodal.AJAX_SUCCESS = 'jsmodal:ajax:success'),
		(o.jsmodal.AJAX_FAIL = 'jsmodal:ajax:fail'),
		(o.jsmodal.AJAX_COMPLETE = 'jsmodal:ajax:complete'),
		(o.fn.jsmodal = function (t) {
			return 1 === this.length && new o.jsmodal(this, t), this;
		}),
		o(i).on('click.jsmodal', 'a[rel~="jsmodal:close"]', o.jsmodal.close),
		o(i).on('click.jsmodal', 'a[rel~="jsmodal:open"]', function (t) {
			t.preventDefault(), o(this).jsmodal();
		});
});
