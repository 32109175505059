$(document).ready(function(){
	const faq = $('[data-shortcode-faq]');
	const faqItem = faq.find('[data-shortcode-faq-item]')

	faqItem.each(function(){
		$(this).on('click', function(){
			if(!$(this).hasClass('is-active')){
				$(this).addClass('is-active');
				$(this).closest(faq).find('[data-shortcode-faq-item]').not(this).find('.c-shortcode-faq__answer').slideUp(400);
				$(this).find('.c-shortcode-faq__answer').slideDown(400);
				$(this).siblings().removeClass('is-active');
			} else {
				$(this).removeClass('is-active');
				$(this).find('.c-shortcode-faq__answer').slideUp(400);
			}
		})
	})
})
