const logoContextMenu = {
	menu: `<div class="c-logo-context-menu">
			<ul class="c-logo-context-menu__items">
				<li class="c-logo-context-menu__item c-logo-context-menu__item--svg-download">
					<a class="c-logo-context-menu__anchor" href="https://chargebacks911.com/brand-resources/" target="_blank">Download Brand Assets
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4">
							<path d="M8.75 2.75a.75.75 0 0 0-1.5 0v5.69L5.03 6.22a.75.75 0 0 0-1.06 1.06l3.5 3.5a.75.75 0 0 0 1.06 0l3.5-3.5a.75.75 0 0 0-1.06-1.06L8.75 8.44V2.75Z" />
							<path d="M3.5 9.75a.75.75 0 0 0-1.5 0v1.5A2.75 2.75 0 0 0 4.75 14h6.5A2.75 2.75 0 0 0 14 11.25v-1.5a.75.75 0 0 0-1.5 0v1.5c0 .69-.56 1.25-1.25 1.25h-6.5c-.69 0-1.25-.56-1.25-1.25v-1.5Z" />
						</svg>
					</a>
				</li>
			</ul>
		</div>`,
	show: function (event) {
		event.preventDefault();

		if ($('.c-logo-context-menu').length === 0) {
			$('body').append(this.menu);
		}

		// Position the menu
		$('.c-logo-context-menu').css({
			top: event.pageY,
			left: event.pageX,
			position: 'absolute',
			display: 'block',
		});
	},
};

$(document).ready(function () {
	$('.c-main-menu__logo, .c-footer__logo img').on('contextmenu', function (event) {
		logoContextMenu.show(event);
	});

	$(document).on('click', function () {
		$('.c-logo-context-menu').hide();
	});
});
