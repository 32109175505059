$(document).ready(function(){

	// $('.js-signup-button').click(function ($e) {
	//   $e.preventDefault();
	//   var thisDataTarget = "#hpSignup";
	//   tawModal.show(thisDataTarget);
	// });
	//
	// $('.close').click(function () {
	//   $('#hpSignup').removeClass('in').css('display', 'none');
	// });
	//
	// // Smooth Modal
	//
	// var tawModal = (function () {
	//   function show(dataTarget) {
	//     $(dataTarget).addClass("taw-modal--active").fadeIn("fast");
	//     $(dataTarget + " .taw-modal__inner").addClass(
	//       "taw-modal-inner--active"
	//     );
	//   }
	//
	//   function hide() {
	//     $(".taw-modal").removeClass("taw-modal--active").fadeOut("fast");
	//     $(".taw-modal__inner").removeClass("taw-modal-inner--active");
	//   }
	//
	//   $('[data-toggle="modal"]').click(function ($e) {
	//     $e.preventDefault();
	//     var thisDataTarget = $(this).attr("data-target");
	//     show(thisDataTarget);
	//   });
	//
	//   $('[data-toggle="modal-close"]').click(function ($e) {
	//     $e.preventDefault();
	//     hide();
	//   });
	//
	//   return {
	//     show: show,
	//     hide: hide
	//   };
	// })();

})
